<template>
  <div v-if="breadcrumbs && breadcrumbs.length > 0" class="d-flex mb-4 mt-1">
    <div class="d-flex align-center white rounded elevation-2">
      <router-link
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        :to="breadcrumb.link ? breadcrumb.link : ''"
        :class="{ 'ap-black--text cursor-default': breadcrumb.disabled }"
        class="d-flex align-center text-decoration-none"
      >
        <div class="d-flex align-center py-3 px-4">
          <v-icon v-if="index === 0" color="primary" size="24">
            $mdi-chevron-left
          </v-icon>
          {{
            typeof breadcrumb.name === 'function'
              ? breadcrumb.name($route)
              : breadcrumb.name
          }}
        </div>
        <v-divider v-if="!breadcrumb.disabled" vertical />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    breadcrumbs() {
      return this.$route.meta.breadcrumb
    },
  },
}
</script>
