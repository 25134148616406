<template>
  <v-sheet class="d-flex flex-grow-1" color="transparent">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8">
          <DashboardBreadcrumb />
        </v-col>
        <v-col v-if="$route.meta.introSteps" cols="12" md="4">
          <v-btn
            outlined
            class="ml-2 my-3 pl-2 float-right text-capitalize white"
            color="ap-light-blue"
            @click="openTutorialWizard"
          >
            <v-icon right dark class="mr-2"> $mdi-school-outline </v-icon>
            Tutorial
          </v-btn>
        </v-col>
      </v-row>

      <router-view />
    </v-container>
  </v-sheet>
</template>

<script>
import DashboardBreadcrumb from '@/components/shared/DashboardBreadcrumb'
import { intro } from '@/services/intro'

export default {
  components: {
    DashboardBreadcrumb,
  },
  methods: {
    openTutorialWizard() {
      intro
        .setOptions({
          disableInteraction: true,
          showBullets: false,
          tooltipClass: 'introPopup',
          steps: this.$route.meta.introSteps(),
        })
        .start()
    },
  },
}
</script>
